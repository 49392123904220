import React, { useState } from "react";
import styles from "./layersControl.module.css";
import { List, ListSubheader, Divider, IconButton } from "@material-ui/core";
import { Tune as TuneIcon, Close as CloseIcon } from "@material-ui/icons";
import { FindingGroupControl, LayerControl } from "./layersControl";
import TileFilterControl from "./tileFilterControl";

const Toggle = ({ onMouseEnter }) => {
  return (
    <TuneIcon className={styles.toggle} onMouseEnter={onMouseEnter} />
  );
};

const TileControlList = ({ mapRef, visibility, setVisibility, close }) => {
  return (
    <div onMouseLeave={close}>
      <List>
        <ListSubheader color='default' style={{ fontSize: "1.2rem" }}>Layers</ListSubheader>
        <LayerControl
          key="layerControl"
          visibility={visibility}
          setVisibility={setVisibility}
        />
        <Divider />
        <ListSubheader color='default' style={{ fontSize: "1.2rem" }}>Findings</ListSubheader>
        <FindingGroupControl
          key="findingGroupControl"
          visibility={visibility}
          setVisibility={setVisibility}
        />
        <Divider />
        <ListSubheader color='default' style={{ fontSize: "1.2rem" }}>Tile filters</ListSubheader>
        <TileFilterControl key="tileFilterControl" mapRef={mapRef} />
      </List>
    </div>
  );
};

export default ({ mapRef, visibility, setVisibility }) => {
  const [open, setOpen] = useState(false);

  return (
    <div className={styles.control}>
      {open ? (
        <TileControlList
          mapRef={mapRef}
          visibility={visibility}
          setVisibility={setVisibility}
          close={() => setOpen(false)}
        />
      ) : (
        <Toggle onMouseEnter={() => setOpen(true)} />
      )}
    </div>
  );
};
