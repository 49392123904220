import React from "react";
import { useStoreState } from "easy-peasy";
import {
  Collapse,
  ListItemText,
  ListItemIcon,
  ListItem
} from "@material-ui/core";
import {
  LocationOn as LocationOnIcon,
  LocationOff as LocationOffIcon,
  Layers as LayersIcon,
  LayersClear as LayersClearIcon
} from "@material-ui/icons";
import { labelColorMapping } from "../../../support/colors";
import {
  findingLabels,
  findingLabelVerboseNames
} from "../../../support/findingLabels";
import {
  layerLabels,
  layerLabelVerboseNames
} from "../../../support/layerLabels";

const controlItem = ({ visibility, setVisibility, label, findingGroup }) => {
  const toggleVisibility = label => {
    setVisibility(prev => ({
      ...prev,
      [label]: !prev[label]
    }));
  };

  const onIcon = findingGroup ? <LocationOnIcon /> : <LayersIcon />;
  const offIcon = findingGroup ? <LocationOffIcon /> : <LayersClearIcon />;
  const color = findingGroup ? labelColorMapping[label] : "grey";
  const text = findingGroup
    ? findingLabelVerboseNames[label]
    : layerLabelVerboseNames[label];
  const key = findingGroup
    ? `control-finding-${label}`
    : `control-layer-${label}`;

  return (
    <ListItem
      dense
      button
      style={{ transition: "background-color 0.3s ease-in-out" }}
      onMouseEnter={e => (e.currentTarget.style.backgroundColor = "#f0f0f0")}
      onMouseLeave={e =>
        (e.currentTarget.style.backgroundColor = "transparent")
      }
      onClick={() => {
        toggleVisibility(label);
      }}
      key={key}
    >
      <ListItemIcon style={{ color }}>
        {visibility[label] ? onIcon : offIcon}
      </ListItemIcon>
      <ListItemText style={{ marginLeft: 8 }} id={key} primary={text} />
    </ListItem>
  );
};

export const FindingGroupControl = ({ visibility, setVisibility }) => {
  const showLabeled = useStoreState(state => state.AnalysisRuns.showLabeled);

  return (
    <>
      <Collapse in={showLabeled}>
        {findingLabels.map(label =>
          controlItem({ visibility, setVisibility, label, findingGroup: true })
        )}
      </Collapse>
      {controlItem({
        visibility,
        setVisibility,
        label: null,
        findingGroup: true
      })}
    </>
  );
};

export const LayerControl = ({ visibility, setVisibility }) => {
  return (
    <>
      {layerLabels.map(label =>
        controlItem({ visibility, setVisibility, label, findingGroup: false })
      )}
    </>
  );
};
