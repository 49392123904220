import styles from "./findingsMap.module.css";
import React, { useEffect, useState } from "react";
import { useStoreActions, useStoreState } from "easy-peasy";
import { useLocation, useHistory } from 'react-router-dom';
import { Map, ZoomControl, TileLayer, ScaleControl, withLeaflet, Popup } from "react-leaflet";
import { useHotkeys } from 'react-hotkeys-hook';
import MeasureControlDefault from 'react-leaflet-measure';
import Control from "react-leaflet-control";
import 'leaflet-draw/dist/leaflet.draw.css';
import "react-leaflet-markercluster/dist/styles.min.css";
import "leaflet/dist/leaflet.css";
import NewFindingEditor from './newFindingsEditor'
import bboxPolygon from "@turf/bbox-polygon";
import IconButton from "@material-ui/core/IconButton";
import {ViewList as ViewListIcon} from '@material-ui/icons';
import FindingMarker from "./findingMarker";
import AisLayer from "./ais/aisLayer";
import AisControl from './ais/aisControl';
import SarZonesLayer from './sarZones/sarZoneLayer'
import flipCoords from "../../support/flipCoords";
import { calcBounds } from "../../support/calcBounds";
import LegendControl from "./LegendControl/legendControl.js"

export default ({ mapRef, selectedFinding }) => {
  const [hideFindings, setHideFindings] = useState(false);

  useHotkeys('shift', () => setHideFindings(true), {
    keydown: true,
  });

  useHotkeys('shift', () => setHideFindings(false), {
    keyup: true
  })

  const manualFindingMode = useStoreState(
    (state) => state.NewFindings.manualFindingMode
  );

  const findings = useStoreState(
    (state) => state.AnalysisRuns.displayedFindings)

  const [visibility, setVisibility] = useState({
    "refugee_boat": true,
    "boat": true, 
    "not_boat": true,
    "floating_object": true,
    "ais": true,
    "sarZone": false,
    null : true
  });
  

  const location = useLocation();
  const history = useHistory();

  const currentAnalysisRunId = useStoreState(
    (state) => state.AnalysisRuns.currentAnalysisRun.analysis_run_id
  );

  useEffect(() => {
    mapRef.current.leafletElement.on('contextmenu', handleCoordinateCopy);
    return () => {
      mapRef.current.leafletElement.off('contextmenu', handleCoordinateCopy);
    };
  }, [mapRef]);

  const [openPopup, setOpenPopup] = useState(false);
  const [popupPosition, setPopupPosition] = useState(null);

  const handleCoordinateCopy = (event) => {
    const lat = event.latlng.lat;
    const lng = event.latlng.lng;
    const coordinates = `${lat}, ${lng}`;
    navigator.clipboard.writeText(coordinates);
    setPopupPosition(event.latlng);
    setOpenPopup(true);
    setTimeout(() => setOpenPopup(false), 1000);
  }

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const lat = parseFloat(query.get('lat')) || 35.991341;
    const lng = parseFloat(query.get('lng')) || 12.689209;
    const zoom = parseInt(query.get('zoom'), 10) || 7;

    mapRef.current.leafletElement.setView([lat, lng], zoom);

    const onMoveEnd = () => {
      const { lat, lng } = mapRef.current.leafletElement.getCenter();
      const zoom = mapRef.current.leafletElement.getZoom();
      const newUrl = `?lat=${lat.toFixed(6)}&lng=${lng.toFixed(6)}&zoom=${zoom}`;
      history.replace(newUrl);
    };

    mapRef.current.leafletElement.on('moveend', onMoveEnd);

    return () => {
      mapRef.current.leafletElement.off('moveend', onMoveEnd);
    };
  }, [location.search, history, mapRef]);


  useEffect(() => {
    fitBounds();
  }, [selectedFinding]);

  const toggleFindingsPanel = useStoreActions(
    (actions) => actions.AnalysisRuns.toggleFindingsPanel
  );



  const MeasureControl = withLeaflet(MeasureControlDefault);

  const measureOptions = {
    position: "topright",
    primaryLengthUnit: "meters",
    secondaryLengthUnit: "kilometers",
    primaryAreaUnit: "sqmeters",
    activeColor: "#db4a29",
    completedColor: "#9b2d14",
  };

 

  const bounds = () => {
    // Center to selected finding center if present else center to all findings
    const defaultCoords = [-116, -8, 139, 72];
    return selectedFinding
      ? calcBounds([selectedFinding])
      : findings.length > 0
      ? calcBounds(findings)
      : defaultCoords;
  };

  const fitBounds = () => {
    const coords = bboxPolygon(bounds()).geometry.coordinates[0];
    mapRef.current.leafletElement.fitBounds(flipCoords(coords), {maxZoom : 18});
  };

  return (
    <Map zoomSnap={0.1} zoomControl={false} maxZoom={25} ref={mapRef}>
      <ZoomControl position="topright"></ZoomControl>
      <ScaleControl position="bottomright" />

      <Control position="topleft">
        <IconButton
          className={styles.openPanelButton}
          onClick={() => {
            toggleFindingsPanel(true);
          }}
        >
          <ViewListIcon />
        </IconButton>
      </Control>

      {/* Fallback layer in background */}
      <TileLayer url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}" maxNativeZoom={19} maxZoom={25}/>
      {/* Our satellite images */}
      <TileLayer url={`${process.env.TILING_SERVER_BASE_URL}/tiles/${currentAnalysisRunId}/{z}/{x}/{y}.png`} maxNativeZoom={19} maxZoom={25}/>

      <MeasureControl {...measureOptions} />
      {manualFindingMode ? <NewFindingEditor /> : ""}
      
      {visibility["sarZone"] && <SarZonesLayer />}

      {!hideFindings && findings.map((finding) => (
        <FindingMarker
          key={`finding-marker-${finding.finding_id}`}
          id={`finding-marker-${finding.finding_id}`}
          finding={finding}
          highlighted={finding === selectedFinding}
          labeled={finding.label !== null}
          visible={visibility[finding.label]}

        />
      ))}

      {visibility["ais"] && <AisLayer /> }
      
      <AisControl mapRef={mapRef} />
      <AisLayer />
      {visibility["sarZone"] && <SarZonesLayer />}
      
      <LegendControl mapRef={mapRef} visibility={visibility} setVisibility={setVisibility} />

      {openPopup && (
        <Popup
          position={popupPosition}
          onClose={() => setOpenPopup(false)}
          autoPan={false}
        >
          <div>Position copied to clipboard!</div>
        </Popup>
      )}
      
    </Map>
  );
};
