import { Slider, Button } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import styles from "./layersControl.module.css";

export default ({ mapRef }) => {

  const [contrast, setContrast] = useState(1);
  const [brightness, setBrightness] = useState(1);
  const [saturate, setSaturate] = useState(1);

  // load existing filter
  useEffect(() => {
    const existingFilter = document.getElementById("tile-filter");
    if (existingFilter) { 
      const innerHTML = existingFilter.innerHTML;

      const filterRegex = /filter:\s*contrast\((.*?)\)\s*brightness\((.*?)\)\s*saturate\((.*?)\);/;

      const match = innerHTML.match(filterRegex);
      if (match) {
        const [, c, b, s] = match;
        const contrastNum = Number.parseFloat(c);
        const brightnessNum = Number.parseFloat(b);
        const saturateNum = Number.parseFloat(s);

        if (!isNaN(contrastNum)) setContrast(contrastNum);
        if (!isNaN(brightnessNum)) setBrightness(brightnessNum);
        if (!isNaN(saturateNum)) setSaturate(saturateNum);
      } 
    }
  }, [])

  useEffect(() => {
    const existingFilter = document.getElementById("tile-filter");
    existingFilter && existingFilter.remove();

    const style = document.createElement("style");
    style.setAttribute("id", "tile-filter");

    style.innerHTML = `
      .leaflet-tile {
        filter: contrast(${contrast}) brightness(${brightness}) saturate(${saturate});
      }
    `;

    document.head.appendChild(style);
  }, [contrast, brightness, saturate])

  const reset = () => {
    setContrast(1);
    setBrightness(1);
    setSaturate(1);
  }


  const sliders = () => (
    <div
      onMouseEnter={evt =>  mapRef.current.leafletElement.dragging.disable()}
      onMouseLeave={evt =>  mapRef.current.leafletElement.dragging.enable()}
    >
      <span>Contrast</span>
      <Slider
        defaultValue={contrast} value={contrast}
        step={0.1} min={0.1} max={2}
        onChange={(evt, val) => setContrast(val)}
      />

      <span>Brightness</span>
      <Slider
        defaultValue={brightness} value={brightness}
        step={0.1} min={0.1} max={2}
        onChange={(evt, val) => setBrightness(val)}
      />

      <span>Saturation</span>
      <Slider
        defaultValue={saturate} value={saturate}
        step={0.1} min={0.1} max={2}
        onChange={(evt, val) => setSaturate(val)}
      />

      <div className={styles.filterResetButton}>
        <Button variant="contained" size="small" onClick={reset}>Reset Filter</Button>
      </div>
    </div>
  )

  return (
    sliders()
  )
}
