import React from "react";
import { useStoreActions } from "easy-peasy";
import { Polygon } from "react-leaflet";
import flipCoords from "../../support/flipCoords";
import { labelColorMapping } from "../../support/colors";

export default ({ finding, highlighted, visible }) => {
  const setFindingDetails = useStoreActions(
    actions => actions.AnalysisRuns.setFindingDetails
  );
  const setSelectedFinding = useStoreActions(
    actions => actions.AnalysisRuns.setSelectedFinding
  );
  const toggleFindingsPanel = useStoreActions(
    actions => actions.AnalysisRuns.toggleFindingsPanel
  );

  const transformed = () => {
    // Assume one feature per finding for now, maybe more later
    const coords = finding.location.features[0].geometry.coordinates[0];
    return flipCoords(coords);
  };
  const color = labelColorMapping[finding.label] || "grey";

  const polygonClickHandler = () => {
      setSelectedFinding(finding);
      setFindingDetails({
        finding_id: finding.finding_id
      });
      toggleFindingsPanel(true);
  };

  if (!visible) {
    return <></>;
  }
  return (
    <>
      <Polygon
        positions={transformed()}
        color={color}
        weight={highlighted ? 5 : 3}
        fillOpacity={highlighted ? 0 : 0.1}
        onClick={highlighted ? null : polygonClickHandler}
      />
    </>
  );
};
