import React from 'react';
import { GeoJSON, LayerGroup } from 'react-leaflet';
import italian_sar_zone from './geojsons/italian_sar_zone.json';
import maltese_sar_zone from './geojsons/maltese_sar_zone.json';
import tunesian_sar_zone from './geojsons/tunisian_sar_zone.json';
import libyan_sar_zone from './geojsons/libyan_sar_zone.json';

const style = {
  fillOpacity: 0,
};

const GeoJsonMap = () => {
  return (
    <LayerGroup>
        <GeoJSON data={italian_sar_zone} style={style} />
        <GeoJSON data={tunesian_sar_zone} style={style} />
        <GeoJSON data={maltese_sar_zone} style={style} />
        <GeoJSON data={libyan_sar_zone} style={style} />
    </LayerGroup>
  )
};

export default GeoJsonMap;