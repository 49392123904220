import styles from './findingsController.module.css';
import React, { useEffect } from "react";
import { Link } from 'react-router-dom';
import { useStoreActions, useStoreState } from "easy-peasy";
import { Button, TextField, Typography } from "@material-ui/core";
import {GetAppTwoTone as GetAppTwoToneIcon, CloseTwoTone as CloseTwoToneIcon, AddCircleOutlineTwoTone as AddCircleOutlineTwoToneIcon} from '@material-ui/icons';
import moment from "moment";
import createGpx from '../../support/gpxCreator'

export default ({ mapRef }) => {


  const setCurrentConfidenceThreshold = useStoreActions(
    (actions) => actions.AnalysisRuns.setCurrentConfidenceThreshold
  );
  const currentConfidenceThreshold = useStoreState(
    (state) => state.AnalysisRuns.currentConfidenceThreshold
  );
  const currentAnalysisRun = useStoreState(
    (state) => state.AnalysisRuns.currentAnalysisRun
  );
  const cannotCreateNewFinding = useStoreState(
    (state) =>
      state.NewFindings.manualFindingMode === true ||
      state.NewFindings.currentNewFinding !== null
  );
  const startCreateNewFinding = useStoreActions(
    (actions) => actions.NewFindings.enterManualFindingMode
  );
  const handleConfidenceLimitation = (e) => {
    setCurrentConfidenceThreshold(e.target.value / 100);
  }

  const getThresholdForTenFindings = (findings) => {
    const sortedFindings = [...findings].sort((a, b) => b.confidence - a.confidence);
    const index = sortedFindings.length < 10 ? sortedFindings.length - 1 : 9;
    const epsilon = 1E-9;
    return sortedFindings[index].confidence - epsilon;
  };

  const getInitThreshold = (analysisRun) => {
    if (analysisRun && analysisRun.unfilteredFindings.length > 0) {
      const findings = analysisRun.unfilteredFindings;
      const thresholdForTenFindings = getThresholdForTenFindings(findings);
      return Math.min(0.3, thresholdForTenFindings).toString();
    }
    return '0.3';
  };

  useEffect(
    () => {
      const initThreshold = getInitThreshold(currentAnalysisRun);
      setCurrentConfidenceThreshold(initThreshold);
    },
    [])

    const unfilteredFindings = (currentAnalysisRun && currentAnalysisRun.unfilteredFindings) ? currentAnalysisRun.unfilteredFindings : null;
    const refugeeBoatFindings = unfilteredFindings.filter(finding => finding.label === "refugee_boat");
    const gpxData = refugeeBoatFindings ? createGpx(refugeeBoatFindings) : "";
    const encodedGpx = encodeURIComponent(gpxData);

    const aboveConfidenceThresholdFindings = unfilteredFindings.filter(finding => finding.confidence > currentConfidenceThreshold);


  return (
    <div className={styles.findingsControllerContainer}>
      <Typography 
      className={styles.dateTimeTypography} 
      color="textSecondary"
      variant="subtitle2" 
      >
        Satellite Image captured at <br />
      {moment(currentAnalysisRun.unfilteredFindings[0].sat_image_captured_at).utc().format("YYYY-MM-DD HH:mm:ss [UTC]")}
      </Typography>

      <div className={styles.confidenceFilterInput}>
        <TextField
          label="Min. confidence (%)"
          type="number"
          value={Math.round(currentConfidenceThreshold * 100)}
          inputProps={{ max: 100, step: 1, min: 0 }}
          onChange={handleConfidenceLimitation}
          style={{ width: '100%' }}
        />
      </div>

      <div className={styles.numFindings}>
        <TextField
          label="Num. findings"
          value={`${aboveConfidenceThresholdFindings.length} / ${currentAnalysisRun.unfilteredFindings.length}`}
          disabled={true}
          onKeyDown={(e) => {
            e.preventDefault();
          }}
        />
      </div>

      <Link to={"#"} onClick={() => startCreateNewFinding()}>
        <Button
          className={styles.button}
          variant="contained"
          startIcon={<AddCircleOutlineTwoToneIcon />}
          disabled={cannotCreateNewFinding}
        >
          Add Finding
        </Button>
      </Link>

      <a
        href={`data:text/json;charset=utf-8, ${encodeURIComponent(
          JSON.stringify(currentAnalysisRun.unfilteredFindings)
        )}`}
        download={`${currentAnalysisRun.analysis_run_id}.json`}
      >
        <Button
          className={styles.button}
          variant="contained"
          startIcon={<GetAppTwoToneIcon />}
        >{`Download Json`}</Button>
      </a>

      <a
        href={`data:application/gpx+xml;charset=utf-8,${encodedGpx}`}
        download={`${currentAnalysisRun.analysis_run_id}.gpx`}
      >
        <Button
          className={styles.button}
          variant="contained"
          startIcon={<GetAppTwoToneIcon />}
        >
          {`Download GPX`}
        </Button>
      </a>

      <Link to={"/"}>
        <Button
          className={styles.button}
          variant="contained"
          startIcon={<CloseTwoToneIcon />}
        >
          Close
        </Button>
      </Link>
    </div>
  );
};
